<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar
     v-model="successMsg"
     top
     right
     color="success"
   >
     Your password was reset.
   </v-snackbar>

    <div class="auth-inner">

        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <!-- brand logo -->
            <router-link
              to="/"
              class="brand-logo d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <p class="mb-2">
              Enter your email and we'll send you instructions to reset your password
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                outlined
                label="Email"
                hide-details="auto"
                class="mb-4"
                :rules="[validators.emailValidator]"
                :error-messages="errorMessages.email"
              ></v-text-field>

              <v-btn
                block
                color="primary"
                @click="handleFormSubmit"
              >
                Reset password
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center align-center">
            <router-link
              :to="{name:'auth-login'}"
              class="d-flex align-center text-sm"
            >
              <v-icon
                size="24"
                color="primary"
              >
                {{ icons.mdiChevronLeft }}
              </v-icon>
              <span>Back to login</span>
            </router-link>
          </v-card-actions>
        </v-card>

    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const errorMessages = ref([])
    const successMsg = ref(false)

    const handleFormSubmit = () => {
      // const isFormValid = loginForm.value.validate()
      //
      // if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      errorMessages.value = [];

      axios
        .post(themeConfig.api_prefix + '/auth/recover_account', { email: email.value })
        .then(response => {

          if (response.data.success) {
            successMsg.value = true
          }

        })
        .catch(error => {
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    }

    return {
      isPasswordVisible,
      email,
      handleFormSubmit,
      errorMessages,
      successMsg,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },

      validators: {
        required,
        emailValidator,
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
